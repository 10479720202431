body {
  background-color: #167742;
  margin: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  margin: 0px 10%;
  flex: 1;
}
